.pricing-table-container {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pricing-header {
    font-size: 2rem;
    color: var(--color-light);
    margin-bottom: 0.5rem;
  }
  
  .pricing-subheader {
    font-size: 1.5rem;
    color: var(--color-primary);
    margin-bottom: 2rem;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    border: 1px solid #ddd; /* Border around the entire pricing table */
  }
  
  .pricing-card {
    background-color: var(--color-bg-variant);
    padding: 1.5rem;
    flex: 1;
    max-width: 320px;
    border-left: 1px solid #ddd; /* Border between cards */
    border-right: 1px solid #ddd; /* Border between cards */
    box-shadow: none;
  }
  
  .pricing-card:first-child {
    border-left: none; /* Remove border from the first card */
  }
  
  .pricing-card:last-child {
    border-right: none; /* Remove border from the last card */
  }
  
  .pricing-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .plan-title {
    font-size: 1.5rem;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  
  .plan-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  
  .plan-features li {
    font-size: 1rem;
    color: var(--color-white);
    margin: 0.5rem 0;
  }
  
  .plan-features .disabled {
    color: #f44336;
    text-decoration: line-through;
  }
  
  .plan-price {
    font-size: 1.5rem;
    color: var(--color-white);
    margin-bottom: 1.5rem;
  }
  
  .pricing-button {
    background-color: var(--color-primary);
    color: var(--color-bg);
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pricing-button:hover {
    background-color: var(--color-white);
  }
  .premium-price {
    margin-top: 3.2rem;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .pricing-cards {
      flex-direction: column;
    }
  
    .pricing-card {
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #ddd;
    }
  
    .pricing-card:last-child {
      border-bottom: none;
    }
  }
  