.experience-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the cards horizontally */
    gap: 20px;
    margin: 20px; /* Add all-round margin */
}

.experience-card {
    background-color: var( --color-bg-variant); /* Dark blue background */
    border-radius: 15px;
    padding: 20px;
    width: 45%; /* Adjust the width as needed */
    max-width: 500px; /* Set a max-width to ensure consistency */
    color: #FFF; /* White text color */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-card h3 {
    font-size: 1.5rem;
    color: #0dcaf0; /* Light blue heading color */
    margin-bottom: 10px;
}

.experience-card h4 {
    font-size: 1.2rem;
    color: #7a7f9a; /* Grey subheading color */
    margin-bottom: 10px;
}

.experience-card p {
    font-size: 1rem;
    color: #7a7f9a; /* Grey date color */
    margin-bottom: 20px;
}

.experience-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.experience-card li {
    font-size: 0.95rem;
    color: #FFF;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.experience-card li svg {
    color: #0dcaf0; /* Light blue check icon color */
    margin-right: 10px;
}

.experience-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .experience-card {
        width: 100%; /* Full width on small screens */
        max-width: none; /* Remove max-width constraint */
    }

    .experience-container {
        margin: 10px; /* Adjust margin for small screens */
        gap: 10px; /* Reduce gap between cards */
    }

    .experience-card h3 {
        font-size: 1.2rem; /* Adjust heading size */
    }

    .experience-card h4 {
        font-size: 1rem; /* Adjust subheading size */
    }

    .experience-card p, 
    .experience-card li {
        font-size: 0.9rem; /* Adjust text size */
    }
}
