/* src/components/header/pitch.css */

.pitch__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .pitch__video-wrapper {
    width: 100%;
    max-width: 600px;
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
  }
  .pitch__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  

  
@media screen and (max-width: 768px) {
    .pitch__video-wrapper {
      padding-top: 75%; /* Adjust aspect ratio for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .pitch__video-wrapper {
      padding-top: 75%; /* Square aspect ratio for very small screens */
    }
  }