/* Tabs container styling */
.tabs {
  display: flex;
  justify-content: center; /* Centers the tabs horizontally */
  align-items: center; /* Centers the tabs vertically */
  gap: 20px; /* Adds space between the h2 elements */
}

/* Default tab styling */
.tabs h2 {
  color: var(--color-primary); /* Default color */
  margin: 0; /* Optional: removes any default margin */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: color 0.3s; /* Smooth color transition */
}

/* Active tab styling */
.tabs .tab.active {
  color: white; /* Color when tab is active */
}

  